/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

const deliveryAtom = atom({
  key: "delivery",
  default: null,
});

export const useDeliveriesStore = () => {
  const [delivery, setDelivery] = useRecoilState(deliveryAtom);

  const fetchById = useCallback(async () => {
    if (riderId) {
      const response = await api.get(`/riders/${riderId}/allocated-deliveries`);

      setDelivery({});
    }
  }, [api.get, riderId]);

  const state = {
    delivery,
  };

  return { state, fetchById };
};
