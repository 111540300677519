import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

const modalsState = atom({
  key: "modals",
  default: [],
});

export const useModalStore = () => {
  const [modals, setModals] = useRecoilState(modalsState);

  const openModal = useCallback(
    (component) => {
      setModals([...modals, component]);
    },
    [modals, setModals],
  );

  const closeModal = useCallback(() => {
    setModals(...modals.slice(0, -1));
  }, [modals, setModals]);

  const state = {
    modals,
  };

  return { state, openModal, closeModal };
};
