import Layout from "./components/Layout";

import Cs from "./pages/Cs";
import DeliveriesNew from "./pages/DeliveriesNew";
import History from "./pages/History";
import Password from "./pages/Password";
import SettingPushNotification from "./pages/SettingPushNotification";

export const RouterInfo = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <div>aa</div>,
        label: "main",
      },
      {
        path: "/cs",
        element: <Cs />,
        label: "cs",
      },
      {
        path: "/deliveries-new",
        element: <DeliveriesNew />,
        label: "deliveries-new",
      },
      {
        path: "/history",
        element: <History />,
        label: "history",
      },
      {
        path: "/password",
        element: <Password />,
        label: "update-password",
      },
      {
        path: "/setting-push-notification",
        element: <SettingPushNotification />,
        label: "setting-push-notification",
      },
    ],
  },
];
