import React from "react";

function Modal({ children }) {
  return (
    <div
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-20">
          <div class="relative w-full overflow-hidden rounded-10 bg-white">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
